import deviceIcon from 'src/images/device.png';
import BoldText from "src/SharedComponents/BoldText/BoldText";
import Button from "src/SharedComponents/Button/Button";
import DeviceGrid from '../../../SharedComponents/DeviceGrid/DeviceGrid';
import { formatDateString } from 'src/Utils/DateFormatting/DateFormatter';
import { IDevice } from 'src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo';
import { useState } from 'react';
import UnassignDevice from '../UnassignDevice.tsx/UnassignDevice';

export interface IAssignedDevice{
    device: IDevice;
    updateDevices: () => void;
}

const AssignedDevice: React.FC<IAssignedDevice> = ({device, updateDevices}) => {
    const [showUnassignDeviceConfirmDialog, setshowUnassignDeviceConfirmDialog] = useState<boolean>(false);
    return(
        <>
            {showUnassignDeviceConfirmDialog &&
                <UnassignDevice
                    closeDialog={() => setshowUnassignDeviceConfirmDialog(false)}
                    deviceId={device.deviceId}
                    distributorName={device.distributorName}
                    serialNumber={device.serialNumber}
                    distributorId={device.distributorId}
                    updateDevices={updateDevices}
                />
            }
            <DeviceGrid>
                <div className='col-1 d-flex justify-content-center'>
                    <img src={deviceIcon} alt="device" style={{ width: '40px', height: '55px' }} />
                </div>
                <div className='col-3' style={{ marginLeft: '19px' }}>
                    <BoldText text={device.serialNumber}/>
                    <div className='text-left' style={{ fontSize: '12px' }}>Serial Number</div>
                </div>

                <div className='col-3' style={{ marginLeft: '19px' }}>
                    <BoldText text={device.distributorName}/>
                    <div className='text-left' style={{ fontSize: '12px' }}>Owner</div>
                </div>

                <div className='col-2' style={{ marginLeft: '19px' }}>
                    <BoldText text={ formatDateString(device.assignedDate) }/>
                    <div className='text-left' style={{ fontSize: '12px' }}>Date Of Assignment</div>
                </div>
                <div className='col-2' style={{ marginLeft: '19px' }}>
                    <Button
                        backgroundColor="none"
                        color="#A8927D"
                        text="Unassign"
                        borderColor="#A8927D29"
                        fontSize="12px"
                        width='160px'
                        borderStyle='solid'
                        borderWidth='2px'
                        fontWeight='600'
                        disable={false}
                        onClick={() => {if(device.distributorId) { setshowUnassignDeviceConfirmDialog(true) }}}
                    />
                </div>
            </DeviceGrid>
        </>
    );
}

export default AssignedDevice;