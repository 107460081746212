import deviceIcon from 'src/images/device.png';
import BoldText from "src/SharedComponents/BoldText/BoldText";
import Button from "src/SharedComponents/Button/Button";
import DeviceGrid from '../../../SharedComponents/DeviceGrid/DeviceGrid';
import { formatDateString } from 'src/Utils/DateFormatting/DateFormatter';
import { IDevice, IPartner } from 'src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo';
import { useEffect, useState } from 'react';
import AssignDeviceToPartner from '../AssignDeviceToPartner/AssignDeviceToPartner';
import { useGetDeviceServiceWrapper } from 'src/Utils/Hooks/DeviceService/useGetDeviceServiceWrapper';

export interface IUnassignedDevice{
    device: IDevice;
    partner: IPartner[] | undefined;
    updateDevices:() => void;
}

const UnassignedDevice: React.FC<IUnassignedDevice> = ({device, partner, updateDevices}) => {
    const [showAssignPartner, setShowAssignPartner] = useState<boolean>(false);
    const { reqestResponse, assignDeviceToPartner } = useGetDeviceServiceWrapper();

    useEffect(() => {
        if(reqestResponse){
            updateDevices();
        }
    }, [reqestResponse, updateDevices]);

    return(
        <>
            {
                showAssignPartner && 
                    <AssignDeviceToPartner
                    onClose={() => {setShowAssignPartner(false)}}
                    onSubmit={(distributorId: string) => {
                        assignDeviceToPartner(device.deviceId, distributorId, device.serialNumber);setShowAssignPartner(false)
                    }}
                    serialNumber={device.serialNumber}
                    partners={partner}
                    />
            }
            <DeviceGrid>
                {/* <div className='col-1 d-flex justify-content-center'>
                    <CheckBox />
                </div> */}
                <div className='col-1 d-flex justify-content-center'>
                    <img src={deviceIcon} alt="device" style={{ width: '45px', height: '55px' }} />
                </div>
                <div className='col-3' style={{ marginLeft: '19px' }}>
                    <BoldText text={device.serialNumber}/>
                    <div className='text-left' style={{ fontSize: '12px' }}>Serial Number</div>
                </div>

                <div className='col-3' style={{ marginLeft: '19px' }}>
                    <BoldText text={device.distributorName}/>
                    <div className='text-left' style={{ fontSize: '12px' }}>Owner</div>
                </div>

                <div className='col-2' style={{ marginLeft: '19px' }}>
                    <BoldText text={ formatDateString(device.assignedDate) }/>
                    <div className='text-left' style={{ fontSize: '12px' }}>Date Of Assignment</div>
                </div>
                <div className='col-2' style={{ marginLeft: '19px' }}>
                    <Button
                        backgroundColor="#A8927D29"
                        color="#A8927D"
                        text="Assign to Partner"
                        borderColor="none"
                        fontSize="12px"
                        width='160px'
                        fontWeight='600'
                        disable={false}
                        onClick={() => {setShowAssignPartner(true)}}
                    />
                </div>
            </DeviceGrid>
        </>
    );
}

export default UnassignedDevice;