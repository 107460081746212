import './Devices.css';
import AssignedDevice from './AssignedDevice/AssignedDevice';
import UnassignedDevice from './UnassignedDevices/UnassignedDevices';
import FlushAccordion from 'src/SharedComponents/FlushAccordion/FlushAccordion';
import { IDevice, IPartner } from 'src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo';
import { useAuraSupportInfo } from 'src/Utils/Hooks/UseAuraInfoContext';
import SearchBar from 'src/SharedComponents/SearchBar/SearchBar';
import { useRef, useState } from 'react';
import { useSearch } from 'src/Utils/Hooks/SearchData/useSearch';
import { useGetAuraSupportInfoWrapper } from 'src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfoWrapper';

const Devices: React.FC = () => {
    const { getAuraSupportInfo } = useGetAuraSupportInfoWrapper();
    const { adminDetails } = useAuraSupportInfo();
    const partnerDetails: IPartner[] | undefined = adminDetails?.partners;

    const [searchText, setSearchText] = useState<string>('');

    const getDevicesByAssignment = (assinedtoDistributor: boolean) => {
        return filteredDevices && filteredDevices.filter((device: IDevice) => device.isAssignedToDistributor === assinedtoDistributor)
        .sort((a:IDevice, b:IDevice) => {
            if (a.serialNumber === null || a.serialNumber === undefined) return 1;
            if (b.serialNumber === null || b.serialNumber === undefined) return -1;
            return a.serialNumber.localeCompare(b.serialNumber);
    })};

    const searchKeys = useRef<(keyof IDevice)[]>(['serialNumber', 'distributorName'])
    const filteredDevices = useSearch(adminDetails?.devices, searchText, searchKeys.current);
    
    return (
        <>
            <div>
                <div className="row m-2" style={{width:"97%"}}>
                    <SearchBar onChange={(text:string) => {setSearchText(text) }} />
                </div>
            </div>
            {adminDetails && 
                <div>
                    <FlushAccordion title={`${'Assigned Devices'} (${getDevicesByAssignment(true)?.length ?? 0})`} id="assignedDevices">
                        <>
                            <div className="m-3">
                                {getDevicesByAssignment(true)?.map((device:IDevice, index:number) => (
                                    <div key={index}>
                                        <AssignedDevice device={device} updateDevices={getAuraSupportInfo}/>
                                    </div>
                                ))}
                            </div>
                        </>
                    </FlushAccordion>
                    <hr className='line mt-4'/>
                    <FlushAccordion title={`${'Devices on Stock'} (${getDevicesByAssignment(false)?.length ?? 0})`} id="unassignedDevices">
                        <>
                            <div className="m-3">
                                {getDevicesByAssignment(false)?.map((device:IDevice, index:number) => (
                                    <div key={index}>
                                        <UnassignedDevice device={device} partner={partnerDetails} updateDevices={getAuraSupportInfo}/>
                                    </div>
                                ))}
                            </div>
                        </>
                    </FlushAccordion>
                </div>
            }
        </>
    )
}

export default Devices;