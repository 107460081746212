import { useCallback, useEffect, useRef } from "react";
import { useAuraSupportInfo } from "../UseAuraInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { LicenseType, useDistributorLicenseService } from "./useDistributorLicenseService";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useDistributorLicenseServiceWrapper = () => {
    const { assignLicenseToPartner, unassignLicenseFromPartner, reqestError, reqestLoading, reqestResponse } = useDistributorLicenseService();
    const { setInfobar, setLoader, reset } = useAuraSupportInfo();
    const infoMessage = useRef('');
    const distributorid = useRef('');
    useEffect(() => {
        if(reqestError !== '') {
           logger.logError(JSON.stringify(reqestError))
           setInfobar({message:reqestError,messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
       setLoader(reqestLoading)
    },[reqestLoading, setLoader])
    
    useEffect(() => {
       if(reqestResponse) {
        logger.logInformation(infoMessage.current + `Distributor Id is ${distributorid.current}`)
        setInfobar({message:infoMessage.current, messageType: InfoMessageType.success})
       }
    }, [reqestResponse, setInfobar])


    const assignLicensePartner = useCallback((licenseType:LicenseType, distributorId:string, entitlementId:string, distributorName: string) => {
        reset();
        distributorid.current = distributorId;
        assignLicenseToPartner(licenseType, entitlementId, distributorId);
        infoMessage.current = `License ${entitlementId} is assigned to partner ${distributorName} successfully.`;
    },[assignLicenseToPartner, reset]) 

    const unassignLicensePartner = useCallback((licenseType:LicenseType, distributorId:string, entitlementId:string, distributorName: string) => {
        reset();
        distributorid.current = distributorId;
        unassignLicenseFromPartner(licenseType, entitlementId, distributorId);
        infoMessage.current = `License ${entitlementId} is unassigned from partner ${distributorName} successfully.`;
    },[unassignLicenseFromPartner, reset]) 

    return {reqestResponse, assignLicensePartner, unassignLicensePartner}
} 