import React,{useRef, useState} from "react";
import Button from "src/SharedComponents/Button/Button";
import PopupDialog from "src/SharedComponents/PopupDialog/PopupDialog";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import close from 'src/images/close.png';
import './AssignLicenseToPartner.css';
import { IPartner } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import SearchBar from "src/SharedComponents/SearchBar/SearchBar";
import useSortableData, { SortConfig } from "src/Utils/Hooks/SortableData/useSortableData";
import TableHead from "src/SharedComponents/Table/TableHead";
import { useSearch } from "src/Utils/Hooks/SearchData/useSearch";

export interface IAssignLicenseToPartner{
    onClose: () => void;
    partners: IPartner[] | undefined;
    onSubmit: (distributorId:string, distributorName: string) => void;
    entitlementId: string;
    licenseType: string;
}

const AssignLicenseToPartner:React.FC<IAssignLicenseToPartner> = ({ onClose, onSubmit, partners, entitlementId, licenseType}) => {
    let conf: SortConfig<IPartner> = {
        key: 'name',
        direction: 'ascending'
    };

    const [searchText, setSearchText] = useState<string>('');
    const searchKeys = useRef<(keyof IPartner)[]>(['name'])
    const filteredPartners = useSearch(partners, searchText, searchKeys.current);
    const { items, requestSort, sortConfig } = useSortableData(filteredPartners, conf);

    return(
        <>
            <div className="assignLicenseToPartner">
                <PopupDialog onClose={() => onClose()} marginTop="120px" popupWidth="45%">
                    <div className="assign-license-header d-flex flex-column row">
                        <div className="row">
                            <div className="col-md-10">
                                <span style={{ fontWeight: '600', fontSize: '24px' }}>Assign {licenseType} License "{entitlementId}"</span>
                            </div>
                            <div className="col-md-2">
                                <i style={{ color: '#A8927D', cursor: 'pointer' }} onClick={() => { onClose() }}>
                                    <img src={close} className='close-icon' alt="close" />
                                </i>
                            </div>
                        </div>
                        <div className="row ps-4" style={{ fontSize: '16px', color: '#A8927D', fontWeight: '400' }}>
                            Select a Partner
                        </div>
                        <div className="row mt-4">
                            <SearchBar onChange={(text:string) => {setSearchText(text) }} />
                        </div>
                    </div>
                    <div className="table-responsive" style={{overflowX:"hidden", borderRadius:'20px', marginBottom:'2px'}}>
                    <table data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                            <thead className={`${tableStyles.tableHead}`}>
                                <tr key={'header'}>
                                    <TableHead<IPartner>
                                        columnName="Partner Name" 
                                        paddingLeft="25px"
                                        requestSort={requestSort}
                                        sortConfig={sortConfig}
                                        columnkey="name"
                                    />
                                    <TableHead<IPartner>
                                        columnName="City" 
                                        paddingLeft="25px"
                                        requestSort={requestSort}
                                        sortConfig={sortConfig}
                                        columnkey="city"
                                    />
                                    <TableHead<IPartner>
                                        columnName="Country"
                                        requestSort={requestSort}
                                        sortConfig={sortConfig}
                                        columnkey="country"
                                    />
                                    <th className={tableStyles.heading}>{ }</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((partner, i) => (
                                    <tr key={i}>
                                        <td className={tableStyles.data} style={{width:"40%", paddingLeft:'25px' }}>
                                            {partner.name}
                                        </td>
                                        <td className={tableStyles.data} style={{width:"30%", paddingLeft:'25px' }}>
                                            {partner.city}
                                        </td>
                                        <td className={tableStyles.data} style={{width:"30%" }}>
                                            {partner.country}
                                        </td>
                                        <td className={tableStyles.data} style={{ fontWeight: 600}}>
                                            <Button
                                                backgroundColor="#A8927D"
                                                color="white"
                                                text="Assign"
                                                borderColor="none"
                                                fontSize="12px"
                                                width='127px'
                                                onClick={() => { onSubmit(partner.distributorId, partner.name) }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            
                            </tbody>
                        </table>
                    </div>
                </PopupDialog>
            </div>
        </>
    );
}

export default React.memo(AssignLicenseToPartner);