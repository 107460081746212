import { useCallback, useMemo } from "react";
import { usePostRequest } from "../ApiWrappers/usePostRequest"
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";

export enum LicenseType{
    AuraAppLicenses = "UserLicense",
    CloudStorageLicenses = "StorageLicense"
}

interface ILicenseManagement{
    entitlementId:string,
    licenseType:LicenseType
}

export const useDistributorLicenseService = () => {
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
    const {postRequest, reqestError, reqestLoading, reqestResponse} = usePostRequest();

    const assignLicenseToPartner = useCallback((licenseType:LicenseType, entitlementId: string, distributorId:string) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${distributorId}/assign-license`;
        logger.logInformation(`Request to assign license for entitlement id ${entitlementId} to partner ${distributorId} was made`)
        let req:ILicenseManagement = {
            entitlementId: entitlementId,
            licenseType:licenseType
        }
        postRequest<ILicenseManagement>(req,requestUrl)
    },[baseUrl, postRequest]) 


    const unassignLicenseFromPartner = useCallback((licenseType:LicenseType, entitlementId: string, distributorId:string) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${distributorId}/remove-license`;
        logger.logInformation(`Request to unassign license for entitlement id ${entitlementId} from partner ${distributorId} was made`)
        let req:ILicenseManagement = {
            entitlementId: entitlementId,
            licenseType:licenseType
        }
        postRequest<ILicenseManagement>(req,requestUrl)
    },[baseUrl, postRequest])

    return { assignLicenseToPartner, unassignLicenseFromPartner, reqestResponse, reqestLoading, reqestError };
}