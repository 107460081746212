import { useCallback, useMemo } from "react";
import { usePostRequest } from "../ApiWrappers/usePostRequest"
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";
export const useDeviceService = () => {
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
    const {postRequest, reqestError, reqestLoading, reqestResponse} = usePostRequest();

    const assignToPartner = useCallback((deviceId:string, distributorId:string) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${distributorId}/assign-device`;
        logger.logInformation(`Request to assign device ${deviceId} to partner ${distributorId} was made`)
        postRequest<string>(deviceId,requestUrl)
    },[baseUrl, postRequest]) 

    const removefromPartner = useCallback((deviceId:string, distributorId:string) => {
        logger.logInformation(`Request to Unassign device ${deviceId} from partner ${distributorId} was made`)
        const requestUrl = `${baseUrl}api/v1/distributor/${distributorId}/remove-device`;
        postRequest<string>(deviceId,requestUrl)
    },[baseUrl, postRequest]) 
    return { assignToPartner, removefromPartner, reqestResponse, reqestLoading, reqestError };
}