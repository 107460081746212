import GridViewCollapase from "src/SharedComponents/GridViewCollapse/GridViewCollapse";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import { IPartner, IStorageLicensesInfo } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import { useEffect, useState } from "react";
import { formatLicenseType } from "src/Utils/LicenseTypeFormatter/LicenseTypeFormatter";
import { formatDateString } from "src/Utils/DateFormatting/DateFormatter";
import Button from "src/SharedComponents/Button/Button";
import AssignLicenseToPartner from "../../AssignLicenseToPartner/AssignLicenseToPartner";
import { useDistributorLicenseServiceWrapper } from "src/Utils/Hooks/LicenseService/useDistributorLicenseServiceWrapper";
import { LicenseType } from "src/Utils/Hooks/LicenseService/useDistributorLicenseService";

export interface ICloudStorageLicensesUnassigned{
    cloudStorageLicensesUnassigned : IStorageLicensesInfo[];
    partners: IPartner[] | undefined;
    updateLicenses: () => void;
}

const CloudStorageLicensesUnassigned: React.FC<ICloudStorageLicensesUnassigned> = ({cloudStorageLicensesUnassigned, partners, updateLicenses}) => {
    const [showAssignPartner, setShowAssignPartner] = useState<boolean>(false);
    const [entitlementId, setEntitlementId] = useState<string>("");

    const { reqestResponse, assignLicensePartner } = useDistributorLicenseServiceWrapper();
        
        useEffect(() => {
            if(reqestResponse){
                updateLicenses();
            }
        }, [reqestResponse, updateLicenses]);

    return(
        <>
            {
                showAssignPartner && 
                <AssignLicenseToPartner
                    onClose={() => {setShowAssignPartner(false)}}
                    onSubmit={(distributorId: string, distributorName: string) => {
                        assignLicensePartner(LicenseType.CloudStorageLicenses, distributorId, entitlementId, distributorName);
                        setShowAssignPartner(false)
                    }}
                    entitlementId={entitlementId}
                    partners={partners}
                    licenseType="Storage"
                />
            }
            <GridViewCollapase title="On Stock" id="cloud-storage-licenses-unassigned">
                <div>
                    <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                        <thead className={`${tableStyles.tableHead}`}>
                            <tr key={'header'}>
                                <th className={tableStyles.heading} style={{width: '30%', paddingTop: '0px'}}>Entitlement ID</th>
                                <th className={tableStyles.heading} style={{width: '20%', paddingTop: '0px'}}>License Type</th>
                                <th className={tableStyles.heading} style={{width: '15%', paddingTop: '0px'}}>Valid Until</th>
                                <th style={{width: '15%', paddingTop: '0px'}}></th>
                                <th style={{width: '20%', paddingTop: '0px'}}></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cloudStorageLicensesUnassigned.map((license: IStorageLicensesInfo, index: number) => (
                                    <tr key={index}>
                                        <td className={tableStyles.data} style={{ fontWeight: '600' }}>{license?.entitlementId}</td>
                                        <td className={tableStyles.data}>{formatLicenseType(license?.licenseType)}</td>
                                        <td className={tableStyles.data}>{license?.licenseEndDate ? formatDateString(license.licenseEndDate) : '-'}</td>
                                        <td></td>
                                        <td></td>
                                        <td className={tableStyles.data} style={{textAlign: 'end'}}>
                                            <Button
                                                backgroundColor="#A8927D29"
                                                color="#A8927D"
                                                text="Assign to Partner"
                                                borderColor="none"
                                                fontSize="12px"
                                                width='172px'
                                                fontWeight='600'
                                                disable={true}
                                                onClick={() => {setEntitlementId(license?.entitlementId);setShowAssignPartner(true)}}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </GridViewCollapase>
        </>
    );
}

export default CloudStorageLicensesUnassigned;