import React from "react";
import './OpenPartner.css'
import { IPartner, UserStatus } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import GridView from "src/SharedComponents/GridView/GridView";
import LogoText from "src/SharedComponents/LogoText/LogoText";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import personLogo from 'src/images/person-icon.svg';

interface IOpenPartnerParams {
  partner: IPartner;
  onClose: () => void;
}

const OpenPartner: React.FC<IOpenPartnerParams> = ({ partner, onClose }) => {
  const pendingText = partner.auraSupportPartnerAdminUserStatus === UserStatus.Invited ? 'Pending' : '';

  const address = () => {
    const addressParts = [partner.streetAddress, partner.city, partner.country];
    return addressParts.filter(part => part).join(", ");
  }

  return (
    <div>
      {/* Breadcrumb */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><span onClick={onClose} className="clickable-item">Partners</span></li>
          <li className="breadcrumb-item active" aria-current="page">{partner.name}</li>
        </ol>
      </nav>

      <div className="partner-info" >
        <GridView title={partner?.name}>
          <div className="d-flex flex-column">
            <LogoText logoSrc={personLogo} text="" additionalText={pendingText}/>
            <LogoText logoSrc={mailLogo} text={partner.email} />
            <LogoText logoSrc={phoneLogo} text={partner.contactNumber} />
            <LogoText logoSrc={mapPinLogo} text={address()} />
          </div>
        </GridView>
      </div>
    </div>
  );
};

export default OpenPartner;
