import { useAuraSupportInfo } from "src/Utils/Hooks/UseAuraInfoContext";
import AuraAppLicenses from "./AuraAppLicenses/AuraAppLicenses";
import CloudStorageLicenses from "./CloudStorageLicenses/CloudStorageLicenses";
import { IPartner } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import { useGetAuraSupportInfoWrapper } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfoWrapper";

const Licenses: React.FC = () => {
    const { adminDetails } = useAuraSupportInfo();
    const { getAuraSupportInfo } = useGetAuraSupportInfoWrapper();
    const partnerDetails: IPartner[] | undefined = adminDetails?.partners;

    return(
        <>
            {adminDetails && 
                <>
                    <div>
                        <AuraAppLicenses auraAppLicenses={adminDetails?.licenses?.userLicencesInfo} partner={partnerDetails} updateLicenses={getAuraSupportInfo}/>
                    </div>
                    <div className="pt-3">
                        <CloudStorageLicenses cloudStorageLicenses={adminDetails?.licenses?.storageLicensesInfo} partner={partnerDetails} updateLicenses={getAuraSupportInfo}/>
                    </div>
                </>
            }
        </>
    );
}

export default Licenses;