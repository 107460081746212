import FlushAccordion from "src/SharedComponents/FlushAccordion/FlushAccordion";
import AuraWeb from "src/images/AuraWeb.png";
import AuraAppLicensesAssigned from "./AuraAppLicensesAssigned/AuraAppLicensesAssigned";
import AuraAppLicensesUnassigned from "./AuraAppLicensesUnassigned/AuraAppLicensesUnassigned";
import { IPartner, IUserLicencesInfo } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";

export interface IAuraAppLicenses{
    auraAppLicenses: IUserLicencesInfo[];
    partner: IPartner[] | undefined;
    updateLicenses: () => void;
}

const AuraAppLicenses: React.FC<IAuraAppLicenses> = ({auraAppLicenses, partner, updateLicenses}) => {
    const getLicensesByPartner = (assignedToPartner: boolean) => {
        return auraAppLicenses && auraAppLicenses?.filter((license: IUserLicencesInfo) => license?.isAssignedToPartner === assignedToPartner);
    }

    return(
        <>
            <FlushAccordion title="Aura App Licenses" id="AuraAppLicenses" titleImage={AuraWeb} titleImageWidth="72px">
                <div className="pt-4">
                    {
                        getLicensesByPartner(true).length > 0 ? <AuraAppLicensesAssigned auraAppLicensesAssigned={getLicensesByPartner(true)} updateLicenses={updateLicenses}/> : <></>
                    }
                    {
                        getLicensesByPartner(false).length > 0 ? <AuraAppLicensesUnassigned auraAppLicensesUnassigned={getLicensesByPartner(false)} partners={partner} updateLicenses={updateLicenses}/> : <></>
                    }
                </div>
            </FlushAccordion>
        </>
    );
}

export default AuraAppLicenses;