import GridViewCollapase from "src/SharedComponents/GridViewCollapse/GridViewCollapse";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import { IUserLicencesInfo } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import { useState } from "react";
import { formatDateString } from "src/Utils/DateFormatting/DateFormatter";
import Button from "src/SharedComponents/Button/Button";
import UnassignLicenseFromPartner from "../../UnassignLicenseFromPartner/UnassignLicenseFromPartner";

export interface IAuraAppLicensesAssigned{
    auraAppLicensesAssigned: IUserLicencesInfo[];
    updateLicenses: () => void;
}

const AuraAppLicensesAssigned: React.FC<IAuraAppLicensesAssigned> = ({auraAppLicensesAssigned, updateLicenses}) => {
    const [showUnassignLicenseConfirmDialog, setshowUnassignLicenseConfirmDialog] = useState<boolean>(false);
    const [entitlementId, setEntitlementId] = useState<string>("");
    const [distributorId, setDistributorId] = useState<string>("");
    const [distributorName, setDistributorName] = useState<string>("");

    return(
        <>
            {showUnassignLicenseConfirmDialog &&
                <UnassignLicenseFromPartner
                    entitlementId={entitlementId}
                    distributorId={distributorId}
                    distributorName={distributorName}
                    closeDialog={() => setshowUnassignLicenseConfirmDialog(false)}
                    updateLicenses={updateLicenses}
                />
            }
            <GridViewCollapase title="Assigned" id="aura-app-licenses-assigned">
                <div>
                    <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                        <thead className={`${tableStyles.tableHead}`}>
                            <tr key={'header'}>
                                <th className={tableStyles.heading} style={{width: '30%', paddingTop: '0px'}}>Entitlement ID</th>
                                <th className={tableStyles.heading} style={{width: '20%', paddingTop: '0px'}}>License Type</th>
                                <th className={tableStyles.heading} style={{width: '15%', paddingTop: '0px'}}>Valid Until</th>
                                <th className={tableStyles.heading} style={{width: '15%', paddingTop: '0px'}}>Seats</th>
                                <th className={tableStyles.heading} style={{width: '20%', paddingTop: '0px'}}>Assigned to</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                auraAppLicensesAssigned.map((license: IUserLicencesInfo, index: number) => (
                                    <tr key={index}>
                                        <td className={tableStyles.data} style={{ fontWeight: '600' }}>{license?.entitlementId}</td>
                                        <td className={tableStyles.data}>{license?.licenseType}</td>
                                        <td className={tableStyles.data}>{license?.licenseEndDate ? formatDateString(license?.licenseEndDate): '-'}</td>
                                        <td className={tableStyles.data}>{license?.maximumSeats}</td>
                                        <td className={tableStyles.data}>{license?.distributorName}</td>
                                        <td className={tableStyles.data} style={{textAlign: 'end'}}>
                                            <Button
                                                    backgroundColor="#fff"
                                                    color="#A8927D"
                                                    text="Unassign"
                                                    borderColor="#A8927D29"
                                                    fontSize="12px"
                                                    width='172px'
                                                    borderStyle='solid'
                                                    borderWidth='2px'
                                                    fontWeight='600'
                                                    disable={false}
                                                    onClick={() => {
                                                        setshowUnassignLicenseConfirmDialog(true); 
                                                        setEntitlementId(license?.entitlementId);
                                                        setDistributorId(license?.distributorId);
                                                        setDistributorName(license?.distributorName);
                                                    }}
                                                />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </GridViewCollapase>
        </>
    );
}

export default AuraAppLicensesAssigned;