interface IUnassignLicenseInstructions {
    entitlementId: string;
}

const UnassignLicenseInstructions:React.FC<IUnassignLicenseInstructions> = ({entitlementId}) => {
    return (
        <>
            <div className="mt-1">
                <p style={{ textAlign: 'center', marginBottom:'0px', fontSize:'14px', fontWeight:'400' }}>
                    The Aura App licence <strong>{entitlementId}</strong> can no longer<br/> be used by this partner or this partner’s clients.
                </p>
            </div>
        </>
    )
}
export default UnassignLicenseInstructions;