export const formatLicenseType = (input: string): JSX.Element[] | string => {
  if (input.includes(',')) {
    // Split the string by commas and replace '*' with 'x', then wrap each part in <span> with a <br/>
    return input
      .split(',')
      .map((part, index) => (
        <span key={index}>
          {part.replace('*', 'x')}
          {index < input.split(',').length - 1 && <br />}
        </span>
      ));
  }

  // If no commas are present, just replace '*' with 'x'
  return input.replace('*', 'x');
};
