import { useCallback, useEffect, useRef } from "react";
import { useAuraSupportInfo } from "../UseAuraInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { useDeviceService } from "./useGetDeviceService";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useGetDeviceServiceWrapper = () => {
    const { assignToPartner, removefromPartner, reqestError, reqestLoading, reqestResponse } = useDeviceService();
    const { setInfobar, setLoader, reset } = useAuraSupportInfo();
    const infoMessage = useRef('');
    const distributorid = useRef('');
    useEffect(() => {
        if(reqestError !== '') {
           logger.logError(JSON.stringify(reqestError))
           setInfobar({message:reqestError,messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
       setLoader(reqestLoading)
    },[reqestLoading, setLoader])
    
    useEffect(() => {
       if(reqestResponse) {
        logger.logInformation(infoMessage.current + `Distributor Id is ${distributorid.current}`)
        setInfobar({message:infoMessage.current, messageType: InfoMessageType.success})
       }
    }, [reqestResponse, setInfobar])


    const assignDeviceToPartner = useCallback((deviceId:string, distributorId:string, serialNumber:string) => {
        reset();
        distributorid.current = distributorId;
        assignToPartner(deviceId, distributorId);
        infoMessage.current = `Device ${serialNumber} is assigned to partner successfully.`;
    },[assignToPartner, reset]) 

    const removeDevicefromPartner = useCallback((deviceId:string, distributorId:string, serialNumber:string) => {
        reset();
        distributorid.current = distributorId;
        removefromPartner(deviceId, distributorId)
        infoMessage.current = `Device ${serialNumber} is removed from partner successfully.`;
    },[removefromPartner, reset]) 


    return {reqestResponse, assignDeviceToPartner, removeDevicefromPartner}
} 