import GridViewCollapase from "src/SharedComponents/GridViewCollapse/GridViewCollapse";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import { IStorageLicensesInfo } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import CloudStorageLicensesAssignedInfo from "../CloudStorageLicensesAssignedInfo/CloudStorageLicensesAssignedInfo";

export interface ICloudStorageLicensesAssigned{
    cloudStorageLicensesAssigned: IStorageLicensesInfo[];
}

const CloudStorageLicensesAssigned: React.FC<ICloudStorageLicensesAssigned> = ({cloudStorageLicensesAssigned}) => {
    return(
        <>
            <GridViewCollapase title="Assigned" id="cloud-storage-licenses-assigned">
                <div>
                    <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                        <thead className={`${tableStyles.tableHead}`}>
                            <tr key={'header'}>
                                <th className={tableStyles.heading} style={{width: '25%', paddingTop: '0px'}}>Entitlement ID</th>
                                <th className={tableStyles.heading} style={{width: '17%', paddingTop: '0px'}}>License Type</th>
                                <th className={tableStyles.heading} style={{width: '13%', paddingTop: '0px'}}>Valid Until</th>
                                <th className={tableStyles.heading} style={{width: '30%', paddingTop: '0px'}}>Assigned to</th>
                                <th style={{width: '15%', paddingTop: '0px'}}></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cloudStorageLicensesAssigned.map((license: IStorageLicensesInfo, index: number) => (
                                    <tr key={index}>
                                        <CloudStorageLicensesAssignedInfo cloudStorageLicensesAssignedInfo={license}/> 
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </GridViewCollapase>
        </>
    );
}

export default CloudStorageLicensesAssigned;