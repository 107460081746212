import React, { useEffect } from "react";
import SmallDialog from "src/SharedComponents/SmallDialog/SmallDialog";
import UnassignDeviceInstructions from "./UnassignDeviceInstructions";
import { useGetDeviceServiceWrapper } from "src/Utils/Hooks/DeviceService/useGetDeviceServiceWrapper";

interface IUnassignDevice{
    deviceId: string;
    distributorName?: string;
    serialNumber: string;
    distributorId:string,
    updateDevices: () => void;
    closeDialog: () => void;
}

const UnassignDevice: React.FC<IUnassignDevice> = ({deviceId, distributorName, serialNumber, distributorId, closeDialog, updateDevices}) => {
    const {reqestResponse, removeDevicefromPartner } = useGetDeviceServiceWrapper();

    useEffect(() => {
        if(reqestResponse){
            updateDevices()
        }
    }, [reqestResponse, updateDevices]);

    return(
        <>
            <SmallDialog
                submitButtonText="Ok"
                title='Unassign Device'
                onClose={() => { closeDialog() }}
                onSubmit={() => {
                    if(distributorId){
                        removeDevicefromPartner(deviceId, distributorId, serialNumber);
                    }
                }} >
                    <UnassignDeviceInstructions serialNumber={serialNumber} />
            </SmallDialog>
        </>
    );
}

export default React.memo(UnassignDevice);