import { formatDateString } from "src/Utils/DateFormatting/DateFormatter";
import { IStorageLicensesInfo } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import { formatLicenseType } from "src/Utils/LicenseTypeFormatter/LicenseTypeFormatter";

export interface ICloudStorageLicensesAssignedInfo{
    cloudStorageLicensesAssignedInfo: IStorageLicensesInfo
}

const CloudStorageLicensesAssignedInfo: React.FC<ICloudStorageLicensesAssignedInfo> = ({cloudStorageLicensesAssignedInfo}) => {
    return(
        <>
            <td className={tableStyles.data} style={{ fontWeight: '600' }}>{cloudStorageLicensesAssignedInfo?.entitlementId}</td>
            <td className={tableStyles.data}>{formatLicenseType(cloudStorageLicensesAssignedInfo?.licenseType)}</td>
            <td className={tableStyles.data}>{cloudStorageLicensesAssignedInfo?.licenseEndDate ? formatDateString(cloudStorageLicensesAssignedInfo.licenseEndDate) : '-'}</td>
            <td className={tableStyles.data}>{cloudStorageLicensesAssignedInfo?.distributorName}</td>
            <td></td>
            <td></td>
        </>
    );
}

export default CloudStorageLicensesAssignedInfo;