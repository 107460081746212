import tableStyles from 'src/Utils/CssModules/table.module.css';
import { IPartner } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";
import PartnerHandler from '../PartnerHandler/PartnerHandler';

interface PartnersInfoProps {
    partnerInfo: IPartner[];
    openUpdatePartner: (partner: IPartner, show: boolean) => void;
  }

const PartnersInfo:React.FC<PartnersInfoProps> = ({partnerInfo, openUpdatePartner }) => {
    return(
        <>
                <div className={`${tableStyles.fixTableHead} ${tableStyles.roundedTable} px-4`}>
                    {partnerInfo  &&
                        <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                            <thead className={`${tableStyles.tableHead}`}>
                                <tr key={'header'}>
                                    <th className={tableStyles.heading}>Partner Name</th>
                                    <th className={tableStyles.heading}>Number of Devices</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {partnerInfo.map((partner, i) => (
                                    <tr key={i}>
                                        <td className={tableStyles.data} style={{ fontWeight: '600' }}>{partner.name}</td>
                                        <td className={tableStyles.data}>{partner.devicesCount}</td>
                                        <td className={tableStyles.data} style={{ display: 'flex', justifyContent: 'end' }}>
                                            <PartnerHandler
                                                partner={partner}
                                                onOpenPartnerClick={(partner) => {
                                                    openUpdatePartner(partner, true);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
        </>
    )
}
export default PartnersInfo;