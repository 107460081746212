import { useCallback, useRef, useState } from 'react';
import Modal from 'src/Utils/CssModules/modal.module.css';
import useOutsideClick from 'src/Utils/Hooks/UseOutSideClick/UseOutSideClick';
import './PopupDialog.css'
export interface IPopupDialogParams{
    children: React.ReactNode,
    onClose: () => void,
    marginTop?: string,
    popupWidth?: string
}
const PopupDialog:React.FC<IPopupDialogParams> = ({children, onClose, marginTop, popupWidth}) => {
    const marginFromTop = marginTop || '200px';
    const [display, setdisplay] = useState('block');
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideClick(wrapperRef, () => { close()})
    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);
    return(
        <>
          <div className='row '>
            <div id="myModal" className={`${Modal.modal}`} style={{ display: display, zIndex: 9, paddingTop:marginFromTop }}>
                <div className={`${Modal.content} content`} ref={wrapperRef} style={{width: popupWidth}}>
                    {children}
                </div>
            </div>
         </div>
        </>
    )
}
export default PopupDialog