import { useState } from 'react';
import arrowIcon from '../../images/arrowIcon.svg';
import './GridViewCollapse.css'

export interface IGridViewCollapaseParams {
    id: string,
    title: string
    children: React.ReactNode
}
const GridViewCollapase: React.FC<IGridViewCollapaseParams> = ({ id, children, title }) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className='row d-flex mb-4 gridview' id={id}>
                <div className='mt-2 ms-2 me-2'>
                    <div className="row p-3" onClick={handleToggle} style={{cursor: 'pointer'}}>
                        <div className='col gridview-header'>
                            <b>{title}</b>
                        </div>
                        <div className='col-2 d-flex justify-content-end align-items-center' style={{ height: '22px' }}>
                            <button 
                                className="btn btn-sm"
                                onClick={handleToggle}
                                aria-label={isOpen ? "Collapse" : "Expand"}
                            >
                                <img 
                                    src={arrowIcon} 
                                    alt={isOpen ? "Collapse" : "Expand"} 
                                    style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease', width: '16px', height: '16px' }}
                                />
                            </button>
                        </div>
                    </div>
                    {isOpen && (
                        <div>
                            {children}
                        </div>
                    )}
                </div>

            </div>
        </>
    )
}
export default GridViewCollapase; 