import FlushAccordion from "src/SharedComponents/FlushAccordion/FlushAccordion";
import Cloud from "src/images/cloud.png";
import CloudStorageLicensesAssigned from "./CloudStorageLicensesAssigned/CloudStorageLicensesAssigned";
import CloudStorageLicensesUnassigned from "./CloudStorageLicensesUnassigned/CloudStorageLicensesUnassigned";
import { IPartner, IStorageLicensesInfo } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";

export interface ICloudStorageLicenses{
    cloudStorageLicenses: IStorageLicensesInfo[];
    partner: IPartner[] | undefined;
    updateLicenses: () => void;
}

const CloudStorageLicenses: React.FC<ICloudStorageLicenses> = ({cloudStorageLicenses, partner, updateLicenses}) => {
    const getLicensesByPartner = (assignedToPartner: boolean) => {
        return cloudStorageLicenses && cloudStorageLicenses?.filter((license: IStorageLicensesInfo) => license?.isAssignedToPartner === assignedToPartner);
    }

    return(
        <>
            <>
            <FlushAccordion title="Cloud Storage Licenses" id="CloudStorageLicenses" titleImage={Cloud} titleImageWidth="40px">
                <div className="pt-4">
                    {
                        getLicensesByPartner(true).length > 0 ? <CloudStorageLicensesAssigned cloudStorageLicensesAssigned={getLicensesByPartner(true)} /> : <></>
                    }
                    {
                        getLicensesByPartner(false).length > 0 ? <CloudStorageLicensesUnassigned cloudStorageLicensesUnassigned={getLicensesByPartner(false)} updateLicenses={updateLicenses} partners={partner}/> : <></>
                    }
                </div>
            </FlushAccordion>
        </>
        </>
    );
}

export default CloudStorageLicenses;