import React, { useEffect } from "react";
import SmallDialog from "src/SharedComponents/SmallDialog/SmallDialog";
import UnassignLicenseInstructions from "./UnassignLicenseInstructions";
import { useDistributorLicenseServiceWrapper } from "src/Utils/Hooks/LicenseService/useDistributorLicenseServiceWrapper";
import { LicenseType } from "src/Utils/Hooks/LicenseService/useDistributorLicenseService";

interface IUnassignLicenseFromPartner{
    entitlementId: string;
    distributorId?: string;
    distributorName: string;
    closeDialog: () => void;
    updateLicenses: () => void;
}

const UnassignLicenseFromPartner: React.FC<IUnassignLicenseFromPartner> = ({entitlementId, distributorId, closeDialog, updateLicenses, distributorName}) => {
    const { reqestResponse, unassignLicensePartner } = useDistributorLicenseServiceWrapper();
        
    useEffect(() => {
        if(reqestResponse){
            updateLicenses();
        }
    }, [reqestResponse, updateLicenses]);

    return(
        <>
            <SmallDialog
                submitButtonText="Ok"
                title='Unassigning License'
                onClose={() => { closeDialog() }}
                onSubmit={() => {
                    if(distributorId){
                        unassignLicensePartner(LicenseType.AuraAppLicenses, distributorId, entitlementId, distributorName)
                    }
                }} >
                    <UnassignLicenseInstructions entitlementId={entitlementId} />
            </SmallDialog>
        </>
    );
}

export default React.memo(UnassignLicenseFromPartner);