import MoreOptionsMenu from "src/SharedComponents/MoreOptions/MoreOptions";
import { IPartner } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";

interface IPartnerHandler{
    partner: IPartner;
    onOpenPartnerClick: (partner: IPartner) => void;
}

const PartnerHandler: React.FC<IPartnerHandler> = ({ partner, onOpenPartnerClick }) => {
    return(
        <>
            <div className="px-2">
                <MoreOptionsMenu>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><div className="dropdown-item" key="1" onClick={() => onOpenPartnerClick(partner)}>Open Partner</div></li>
                    </ul>
                </MoreOptionsMenu>
            </div>
        </>
    );
}

export default PartnerHandler;