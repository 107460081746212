interface IRemoveDeviceInstructions {
    serialNumber:string;
}

const UnassignDeviceInstructions:React.FC<IRemoveDeviceInstructions> = ({serialNumber}) => {
    return (
        <>
            <div className="mt-1">
                <p style={{ textAlign: 'center', marginBottom:'0px', fontSize:'14px', fontWeight:'400' }}>
                    The device <strong>{serialNumber}</strong> can no longer be used by this partner.
                </p>
            </div>
        </>
    )
}
export default UnassignDeviceInstructions;